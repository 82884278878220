import React, { useState } from "react";
import LabeledField from "../components/Form/LabeledField";
import SimpleForm from "../components/Form/SimpleForm";
import { put } from "../lib/api";
import { useMixpanel, usePageViewHook } from "../services/mixpanel";
import AddressControl from "../components/Form/AddressControl";
import TimezoneSelect from "../components/TimezoneSelect";
import EinField from "../Account/EinField";
import { prependHttps } from "../lib/url";

const CompleteBusinessProfile = ({ onComplete }) => {
  const mixpanel = useMixpanel();
  usePageViewHook("complete business profile");
  const [timezone, setTimezone] = React.useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone || "US/Eastern"
  );

  const onSubmit = async (data) => {
    data.timezone = timezone || "US/Eastern";
    data.website = prependHttps(data.website);
    data.tcr_use_case = "private company";
    await put("/shop/{exid}/finalize", data);
    onComplete && onComplete();
    mixpanel.track("complete business profile", data);
  };

  return (
    <SimpleForm
      apiSubmitHandler={onSubmit}
      initialValues={{ business_type: "private company" }}
    >
      <LabeledField
        label="Legal Business Name"
        name="name"
        className="form-control form-control-sm"
        required
        maxLength={150}
      />
      <LabeledField
        label="Doing Business As"
        name="dba_company_name"
        className="form-control form-control-sm"
        required
        maxLength={150}
      />
      <EinField hideLabel />
      <LabeledField
        label="Business Website"
        name="website"
        className="form-control form-control-sm"
        required
      />
      <LabeledField
        label="Business Phone"
        name="phone_number"
        className="form-control form-control-sm"
        required
      />
      <AddressControl />
    </SimpleForm>
  );
};

export default CompleteBusinessProfile;

export const CompleteBusinessProfileDetail = () => (
  <div className="text-center mt-5">
    <div className="d-block d-lg-inline-block text-start">
      <h4 className="mb-4">A real conversation. $8,000 in 8 minutes.</h4>
      <img
        alt="Example"
        className="w-100"
        style={{ maxWidth: "400px" }}
        src="/signup-step-2.png"
      />
    </div>
  </div>
);
