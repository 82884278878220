import React from "react";
import { Route, Routes } from "react-router-dom";
import LocationDetailForm from "./LocationDetailForm";
import LocationHoursForm from "../LocationHoursForm";
import LocationAutomatedResponseForm from "./LocationAutomatedResponseForm";
import LocationDetailNav from "./LocationDetailNav";
import TemplatesSettings from "../../Templates/TemplatesSettings";
import BrowserExtensionSettings from "../../BrowserExtension/BrowserExtensionSettings";
import Webchat from "../../Webchat";
import LocationLightspeedSettings from "./LocationLightspeedSettings";
import LocationVendSettings from "./LocationVendSettings";
import LocationGoogleSettings from "./LocationGoogleSettings";
import Ecwid from "../../components/Ecwid";
import LocationPaymentsSettings from "./LocationPaymentsSettings";
import LocationReviewsSettings from "./LocationReviewsSettings";
import LocationNpsSettings from "./LocationNpsSettings";
import LocationMessengerSettings from "./LocationMessengerSettings";

const LocationDetail = ({ location }) => {
  if (!location) return null;

  return (
    <div>
      <div className="d-flex gap-4">
        <LocationDetailNav />
        <div
          className="container-fluid overflow-scroll"
          style={{ maxHeight: "calc(100vh - 130px)" }}
        >
          <div className="container">
            <Routes>
              <Route
                path="details"
                element={<LocationDetailForm location={location} />}
              />
              <Route
                path="store-hours"
                element={<LocationHoursForm locationId={location.id} />}
              />
              <Route
                path="after-hours-response"
                element={
                  <LocationAutomatedResponseForm locationId={location.id} />
                }
              />
              <Route path="message-templates" element={<TemplatesSettings />} />
              <Route path="reviews" element={<LocationReviewsSettings />} />
              <Route path="nps" element={<LocationNpsSettings />} />
              <Route path="payments/*" element={<LocationPaymentsSettings />} />
              <Route
                path="browser-extension"
                element={<BrowserExtensionSettings />}
              />
              <Route path="website-widget" element={<Webchat />} />

              <Route
                path="lightspeed-r-series/*"
                element={<LocationLightspeedSettings />}
              />
              <Route
                path="lightspeed-x-series/*"
                element={<LocationVendSettings />}
              />
              <Route path="google/*" element={<LocationGoogleSettings />} />
              <Route
                path="meta/messenger"
                element={<LocationMessengerSettings />}
              />
              <Route path="ecwid" element={<Ecwid />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationDetail;
