import React from "react";
import SimpleForm from "../../Form/SimpleForm";
import LabeledField from "../../Form/LabeledField";
import FilterDropDown from "./FilterDropDown";

const FilterBar2 = ({ params, controls, onChange, disabled }) => {
  if (!params) return null;

  return (
    <SimpleForm
      apiSubmitHandler={onChange}
      hideSubmitButton
      disableSuccess
      initialValues={params || {}}
    >
      <div className="d-flex gap-3 justify-content-bottom">
        {controls?.map((controlParams) => {
          if (controlParams.type === "input" || controlParams.type === "date") {
            return (
              <LabeledField
                label={controlParams.title}
                name={controlParams.key}
                type={controlParams.type}
                className="form-control form-control-sm align-self-start"
                style={{ maxWidth: "270px" }}
                {...controlParams}
              />
            );
          }
          if (controlParams.type === "dropdown") {
            return (
              <FilterDropDown name={controlParams.key} {...controlParams} />
            );
          }
          return null;
        })}
        <button
          className="btn btn-sm btn-primary align-self-start mt-auto"
          type="submit"
          disabled={disabled}
        >
          Update
        </button>
      </div>
    </SimpleForm>
  );
};

export default FilterBar2;
